import { Ga4Event } from '@/constants/enum';
import { Icon } from '@shopify/polaris';
import { CreditCardSecureIcon, PackageReturnedIcon, ShieldCheckMarkIcon, ShieldPersonIcon } from '@shopify/polaris-icons';

export const recommendActions: {
  title: string;
  description: string;
  buttonLabel: string;
  icon: React.ReactElement;
  href: string;
  label?: string;
  clickGA: Ga4Event;
  key: string;
}[] = [
    {
      title: 'Comply with global privacy laws',
      description: 'Have cookie consent is a must to protect your store from legal issues that could disrupt business. Non-compliance can lead to large fines.',
      buttonLabel: 'Set up cookie consent',
      icon: <Icon source={ShieldCheckMarkIcon}></Icon>,
      href: 'https://apps.shopify.com/eu-cookies-notification?utm_campaign=crs&utm_source=age&utm_medium=homeexpert',
      clickGA: Ga4Event.ClickCookies,
      key: 'cookies',
    },
    {
      title: 'Manage fraud, disputes and chargeback',
      description:
        'Sending delivery proof to PayPal/ Stripe will help you defend against fraud disputes & chargebacks. It also builds trust with payment processor as a legitimate business.',
      buttonLabel: 'Set up payment protection',
      icon: <Icon source={CreditCardSecureIcon}></Icon>,
      href: 'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=age&utm_medium=homeexpert',
      clickGA: Ga4Event.SynctrackBannerClick,
      key: 'synctrack',
    },
    {
      title: 'Detect and prevent fraudulent activitiese',
      description: 'Protect your store by analyze store traffic, block fraudulent access and auto-canceling high-risk orders.',
      buttonLabel: 'Set up store protection',
      icon: <Icon source={ShieldPersonIcon}></Icon>,
      href: 'https://apps.shopify.com/blockify?utm_campaign=crs&utm_source=age&utm_medium=homeexpert',
      clickGA: Ga4Event.FraudBannerClick,
      key: 'fraud',
    },
    {
      title: 'Validate customer information and address',
      description: 'Strong customer validation enhances security by ensuring only authorized users with legit shipping address can complete transactions.',
      buttonLabel: 'Set up customer validation',
      icon: <Icon source={PackageReturnedIcon}></Icon>,
      href: 'https://apps.shopify.com/blockify-checkout-rules?utm_campaign=crs&utm_source=blockify&utm_medium=homeexpert',
      clickGA: Ga4Event.CheckoutRulesClick,
      key: 'checkout',
    }
  ];