import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Ga4Event } from '@/constants/enum';
// Define the initial state using that type
const initialState = {
  gaFour: {
    [Ga4Event.ImpressTwitter]: false,
    [Ga4Event.ClickTwitter]: false,
    [Ga4Event.ParetoClick]: false,
    [Ga4Event.CheckoutRulesClick]: false,
    [Ga4Event.SynctrackBannerClick]: false,
    [Ga4Event.ClickCookies]: false,
    [Ga4Event.FraudBannerClick]: false
  },
};

const sessionSlice = createSlice({
  name: 'session',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleGaFour: (
      state,
      action: PayloadAction<{
        key: keyof typeof state.gaFour;
      }>,
    ) => {
      state.gaFour[action.payload.key] = true;
    },
  },
});

export const gaFourSelector = createSelector(
  (state: RootState) => state.sessionStorage,
  (state) => state.gaFour,
);

export default sessionSlice;
