import styled from 'styled-components';

export const CheckoutVerificationStyled = styled.div`
  .card-item {
    border: 1px solid #ebebeb;
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 6px;

    .card-item-top {
      padding: 16px;
      height: auto;
      min-height: 210px;
      border-bottom: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;

      .Polaris-BlockStack {
        width: 100%;
        .banner-checkout-ui:nth-of-type(2) {
          margin-left: auto;
        }
      }

      .banner-checkout-ui {
        width: 80%;
      }

      .wrap-checkbox {
        padding: 10px;
        border: 2px solid #007aff;
        border-radius: 6px;
        width: 70%;
        pointer-events: none;
      }
    }

    .card-item-bottom {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: start;
      min-height: 140px;

      .wrap-button {
        margin-top: auto;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }

    .Polaris-Badge .Polaris-Text--root {
      /* font-weight: 600; */
    }
  }
`;
