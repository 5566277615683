import styled, { css, keyframes } from 'styled-components';
import { BannerStyleType } from '@/redux/slice/checkoutVerification.slice';

type Props = {
  banner: {
    value: BannerStyleType;
    colorPrimary: string;
    colorSecondary: string;
  };
  isSelected: boolean;
};

// Smooth sliding keyframes
const smoothSlideAnimation = keyframes`
  0% {
    left: -100%;
    opacity: 0;
  }
  25% {
    opacity: 0.8;
  }
  50% {
    left: 50%;
    opacity: 1;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
`;

// Mixin for skeleton animations
const skeletonAnimation = css<Props>`
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, ${({ banner }) => banner.colorSecondary}, transparent);
  animation: ${({ isSelected }) =>
    isSelected
      ? css`
          ${smoothSlideAnimation} 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite
        `
      : 'none'};
`;

export const BannerCheckoutUIStyled = styled.div<Props>`
  border: 1px solid ${({ banner }) => banner.colorPrimary};
  padding: 10px;
  border-radius: 4px;
  background-color: ${({ banner }) => banner.colorSecondary};

  .banner-content {
    width: calc(100% - 20px);

    .skeleton-heading,
    .skeleton-subHeading {
      height: 20px;
      background-color: ${({ banner }) => banner.colorPrimary};
      border-radius: 4px;
      overflow: hidden;
      position: relative;
    }

    .skeleton-heading {
      width: 100%;
      margin-bottom: 6px;

      &::before {
        ${skeletonAnimation}
      }
    }

    .skeleton-subHeading {
      width: 70%;

      &::before {
        ${skeletonAnimation}
      }
    }
  }

  .Polaris-Icon {
    margin: 0;
  }
`;
