import { BannerStyleType, setupVerifySelector } from '@/redux/slice/checkoutVerification.slice';
import { InlineStack, Text } from '@shopify/polaris';
import React from 'react';
import { BannerCheckoutUIStyled } from './styled';
import { useSelector } from 'react-redux';

type Props = {
  banner: {
    value: BannerStyleType;
    icon: string;
    colorPrimary: string;
    colorSecondary: string;
  };
  heading?: string;
  subHeading?: string;
};

const BannerCheckoutUI: React.FC<Props> = ({ banner, heading, subHeading }) => {
  const setupVerify = useSelector(setupVerifySelector);

  const isSelected = banner.value === setupVerify.bannerStyle;
  return (
    <BannerCheckoutUIStyled isSelected={isSelected} banner={banner} className="banner-checkout-ui">
      <InlineStack align="start" blockAlign="start" gap="200" wrap={false}>
        <img src={banner.icon} alt="" width="20px" />
        <div className="banner-content">
          {heading ? (
            <Text as="h2" variant="headingMd">
              {heading}
            </Text>
          ) : (
            <div className="skeleton-heading" />
          )}

          {subHeading ? <Text as="span">{subHeading}</Text> : <div className="skeleton-subHeading" />}
        </div>
      </InlineStack>
    </BannerCheckoutUIStyled>
  );
};

export default BannerCheckoutUI;
