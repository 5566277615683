import { DeviceType } from '@/constants/enum';
import styled from 'styled-components';
type Props = {
  selectedDevice: DeviceType;
};

export const PreviewCheckoutStyled = styled.div<Props>`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dedede;
  height: 100px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
  height: fit-content;
  overflow: hidden;

  .card-btn-device {
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
  }

  .is-selected {
    background-color: #e3e3e3;
  }

  .Polaris-Icon {
    margin: 0;
  }

  .show-order-summary {
    background: #f5f5f5;
  }

  .checkout-preview {
    display: flex;

    .checkout-input {
      width: 100%;
      padding: 12px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 6px;

      .Polaris-Checkbox__ChoiceLabel {
        align-items: start;
      }
    }

    .checkout-preview-left {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: ${({ selectedDevice }) => (selectedDevice === DeviceType.MOBILE ? '20px 128px' : '20px')};
      background: #fff;
      border-right: ${({ selectedDevice }) => (selectedDevice === DeviceType.MOBILE ? 'none' : '1px solid #ccc;')};
      width: ${({ selectedDevice }) => (selectedDevice === DeviceType.MOBILE ? '100%' : '60%')};

      .checkbox-input {
        min-width: 16px;
        min-height: 16px;
        border: 1px solid #999;
        border-radius: 3px;
        margin-top: 2px;
      }
    }

    .checkout-preview-right {
      display: ${({ selectedDevice }) => (selectedDevice === DeviceType.MOBILE ? 'none' : 'flex')};
      flex-direction: column;
      gap: 16px;
      padding: 20px;
      background: #f5f5f5;
      width: calc(100% - 60% + 1px);
    }
  }

  @media screen and (max-width: 768px) {
    .checkout-preview {
      flex-direction: column;

      .checkout-preview-left {
        width: 100%;
        border-right: none;
      }

      .checkout-preview-right {
        width: 100%;
      }
    }
  }
`;
