import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CheckoutVerifyType, DateOrder } from '@/constants/enum';

export enum ColorsCheckoutUITypes {
  ACCENT = 'accent',
  CRITICAL = 'critical',
  DECORATIVE = 'decorative',
  INFO = 'info',
  SUBDUED = 'subdued',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum CheckoutApplyType {
  All = '0',
  SpecificCollection = '1',
  SpecificProduct = '2',
}

export enum CheckoutValidateMethodType {
  CheckoutBox = '0',
  Input = '1',
}

export enum BannerStyleType {
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
  Critical = 'critical',
}

export type ISetupCheckoutVerify = {
  id: null | undefined | number | string;
  shop: string;
  age: string | number;
  applyTypeBanner: CheckoutApplyType;
  applyTypeInput: CheckoutApplyType;
  showInPageInput: { title: string | undefined; id: number }[];
  showInPageBanner: { title: string | undefined; id: number }[];
  bannerActive: boolean;
  validateInputActive: boolean;
  heading: string;
  subHeading: string;
  method?: CheckoutValidateMethodType;
  checkboxText: string;
  textColor: ColorsCheckoutUITypes;
  dateOrder: DateOrder;
  headingInput: string;
  bannerStyle: BannerStyleType;
  firstActive: string[];
  updateAt?: string;
};

const setupVerify: ISetupCheckoutVerify = {
  id: null,
  shop: '',
  age: '18',
  applyTypeBanner: CheckoutApplyType.All,
  applyTypeInput: CheckoutApplyType.All,
  showInPageInput: [],
  showInPageBanner: [],
  bannerActive: false,
  validateInputActive: false,
  checkboxText: 'I’m over 18 years old',
  dateOrder: DateOrder.YY_MM_DD,
  heading: 'You must at least 18 years old to buy this product',
  subHeading: 'Ensure you read and agree to the Terms & Conditions.',
  headingInput: 'Enter your birth of date',
  method: CheckoutValidateMethodType.CheckoutBox,
  textColor: ColorsCheckoutUITypes.WARNING,
  bannerStyle: BannerStyleType.Info,
  firstActive: [],
};

const setupError = {
  showInPage: '',
  heading: '',
  subHeading: '',
  checkboxText: '',
  headingInput: '',
};

export const initialStateCheckoutVerification = {
  verifyType: CheckoutVerifyType.DEFAULT,

  setupVerify: setupVerify,
  setupVerifyBackup: setupVerify,

  setupError: setupError,
};

export const checkoutVerificationSlice = createSlice({
  name: 'checkout_verification',
  initialState: initialStateCheckoutVerification,
  reducers: {
    handleChangeVerifyType: (state, action: PayloadAction<CheckoutVerifyType>) => {
      state.verifyType = action.payload;
    },
    handleChangeSetupVerify: (state, action: PayloadAction<Partial<ISetupCheckoutVerify>>) => {
      state.setupVerify = {
        ...state.setupVerify,
        ...action.payload,
      };
    },
    handlePushDataCheckoutVerify: (state, action: PayloadAction<ISetupCheckoutVerify>) => {
      state.setupVerify = action.payload;
      state.setupVerifyBackup = action.payload;
      state.setupError = setupError;
    },
    handleSetupErrorVerify: (state, action: PayloadAction<Partial<typeof setupError>>) => {
      state.setupError = {
        ...state.setupError,
        ...action.payload,
      };
    },
    clearAllStateCheckoutVerify: (state) => {
      state.setupVerify = setupVerify;
      state.setupVerifyBackup = setupVerify;
      state.setupError = setupError;
    },
  },
});

export const setupVerifySelector = createSelector(
  (state: RootState) => state.checkoutVerification,
  (state) => state.setupVerify,
);

export const setupVerifyBackupSelector = createSelector(
  (state: RootState) => state.checkoutVerification,
  (state) => state.setupVerifyBackup,
);

export const verifyTypeSelector = createSelector(
  (state: RootState) => state.checkoutVerification,
  (state) => state.verifyType,
);

export const setupErrorVerifySelector = createSelector(
  (state: RootState) => state.checkoutVerification,
  (state) => state.setupError,
);

export const {
  clearAllStateCheckoutVerify,
  handleChangeSetupVerify,
  handleChangeVerifyType,
  handlePushDataCheckoutVerify,
  handleSetupErrorVerify,
} = checkoutVerificationSlice.actions;

export default checkoutVerificationSlice;
