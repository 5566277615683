import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const RecommendedActionStyled = styled.div`
  .Polaris-Icon {
    margin: 0 4px 0 0;
  }
  .btn-cancel {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 511;
  }
  .recommend-app-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .recommend-app-content {
      display: flex;
      flex-direction: column;
      height: 100px;
      gap: 6px;
    }
  }
  .slick-list {
    height: 100%;
    padding: 1rem 0;
    @media screen and (max-width: ${BREAKPOINT.MD}px) {
      padding: 1.5rem 0;
    }
  }
  .slick-track {
    height: 100%;
    .slick-slide {
      padding-right: 16px;
      height: 100%;
      & > div {
        height: 100%;
        & > .Polaris-ShadowBevel {
          height: 100%;
        }
      }
    }
  }
`;
