import styled from 'styled-components';

interface Props {}
export const ProductOrCollectionListStyled = styled.div<Props>`
  .selected-tag-full-width {
    width: 100%;
    margin: 6px 0px 0px 0px;
    display: flex;
  }
`;
