import BannerCheckoutUI from '@/components/BannerCheckoutUI';
import BoldText from '@/components/BoldText';
import { colorsCheckoutUI } from '@/constants/colors';
import { CheckoutVerifyType, DeviceType } from '@/constants/enum';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import {
  BannerStyleType,
  CheckoutValidateMethodType,
  setupVerifySelector,
  verifyTypeSelector,
} from '@/redux/slice/checkoutVerification.slice';
import { BlockStack, Box, Collapsible, Divider, Icon, InlineGrid, InlineStack, Text, Thumbnail } from '@shopify/polaris';
import { CaretDownIcon, CartIcon, ChevronDownIcon, DesktopIcon, MobileIcon, QuestionCircleIcon } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { bannerStyles } from '../BannerVerify';
import { PreviewCheckoutStyled } from './styled';

const PreviewCheckout = () => {
  const { shopifyPlanPlus } = userPlans();

  const verifyType = useSelector(verifyTypeSelector);
  const setupVerify = useSelector(setupVerifySelector);
  const isSkip = useSelector(isSkipApiSelector);

  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery('', { skip: isSkip });

  const [selectedDevice, setSelectedDevice] = useState(DeviceType.DESKTOP);
  const isSelectMobile = selectedDevice === DeviceType.MOBILE;

  const bannerStyleRegular = bannerStyles.find((banner) => banner.value === BannerStyleType.Critical);

  const bannerStyledPlus = bannerStyles.find((banner) => banner.value === setupVerify.bannerStyle);

  return (
    <PreviewCheckoutStyled selectedDevice={selectedDevice} className="preview-checkout">
      <Box paddingBlock="200">
        <InlineStack align="center" blockAlign="center" gap="200">
          <div
            className={`card-btn-device ${selectedDevice === DeviceType.DESKTOP ? 'is-selected' : ''}`}
            onClick={() => setSelectedDevice(DeviceType.DESKTOP)}
          >
            <Icon source={DesktopIcon} />
          </div>
          <div
            className={`card-btn-device ${selectedDevice === DeviceType.MOBILE ? 'is-selected' : ''}`}
            onClick={() => setSelectedDevice(DeviceType.MOBILE)}
          >
            <Icon source={MobileIcon} />
          </div>
        </InlineStack>
      </Box>
      <Divider />

      <Box paddingBlock="400" paddingInline={isSelectMobile ? '3200' : '400'}>
        <InlineStack align="space-between">
          <Text as="h1" variant="headingLg">
            {fetchDataSettings.data?.data.nameShop}
          </Text>
          <Icon source={CartIcon} tone="emphasis" />
        </InlineStack>
      </Box>

      <Collapsible
        id=""
        open={selectedDevice === DeviceType.MOBILE}
        transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
      >
        <Divider />
        <div className="show-order-summary">
          <Box paddingBlock="400" paddingInline={isSelectMobile ? '3200' : '400'}>
            <InlineStack align="space-between">
              <InlineStack blockAlign="center" gap="100">
                <Text as="p" tone="magic-subdued" variant="headingMd">
                  Show order summary
                </Text>
                <Icon source={ChevronDownIcon} tone="magic" />
              </InlineStack>
              <Text as="p" variant="headingMd">
                $25.98
              </Text>
            </InlineStack>
          </Box>
        </div>
      </Collapsible>

      <Divider />
      <div className="checkout-preview">
        <div className="checkout-preview-left">
          <Collapsible
            open={verifyType === CheckoutVerifyType.InfoBanner && setupVerify.bannerActive}
            id=""
            transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
          >
            <BannerCheckoutUI
              banner={shopifyPlanPlus ? bannerStyledPlus! : bannerStyleRegular!}
              heading={setupVerify.heading.trim()}
              subHeading={shopifyPlanPlus ? setupVerify.subHeading.trim() : ' '}
            />
          </Collapsible>

          <BlockStack gap="200">
            <BoldText>Contact</BoldText>
            <div className="checkout-input">
              <Text as="p" variant="bodyLg" tone="subdued">
                Email or mobile phone number
              </Text>
            </div>
          </BlockStack>

          <InlineStack blockAlign="start" gap="200">
            <div className="checkbox-input"></div>
            <p style={{ color: '#000' }}>Email me with news and offers</p>
          </InlineStack>

          {verifyType === CheckoutVerifyType.CheckboxOrDate && (
            <>
              <Collapsible
                open={setupVerify.method === CheckoutValidateMethodType.CheckoutBox && setupVerify.validateInputActive}
                id=""
                transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
              >
                <BlockStack gap="200">
                  <BoldText>Age verification</BoldText>
                  <div className="checkout-input">
                    <InlineStack blockAlign="start" gap="200" wrap={false}>
                      <div className="checkbox-input" />
                      <p style={{ color: colorsCheckoutUI[setupVerify.textColor] }}>{setupVerify.checkboxText}</p>
                    </InlineStack>
                  </div>
                </BlockStack>
              </Collapsible>

              <Collapsible
                open={setupVerify.method === CheckoutValidateMethodType.Input && setupVerify.validateInputActive}
                id=""
                transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
              >
                <BlockStack gap="200">
                  <Text as="p" variant="headingMd" truncate breakWord>
                    {setupVerify.headingInput}
                  </Text>
                  <DateOrderVerify />
                </BlockStack>
              </Collapsible>
            </>
          )}

          <BlockStack gap="200">
            <BoldText>Delivery</BoldText>
            <div className="checkout-input" style={{ padding: '4px 12px' }}>
              <InlineStack align="space-between" blockAlign="center">
                <div>
                  <Text as="p" variant="bodySm" tone="subdued">
                    Country/Region
                  </Text>
                  <Text as="p">Vietnam</Text>
                </div>
                <Icon source={CaretDownIcon} />
              </InlineStack>
            </div>
          </BlockStack>

          <InlineGrid columns={2} gap="200">
            <div className="checkout-input">
              <Text as="p" tone="subdued">
                First name (option)
              </Text>
            </div>
            <div className="checkout-input">
              <Text as="p" tone="subdued">
                Last name
              </Text>
            </div>
          </InlineGrid>
        </div>

        <div className="checkout-preview-right">
          <InlineStack align="space-between" blockAlign="center">
            <InlineStack gap="300" blockAlign="center">
              <Thumbnail
                source="https://burst.shopifycdn.com/photos/black-leather-choker-necklace_373x@2x.jpg"
                alt="Black choker necklace"
              />
              <BlockStack>
                <Text as="p">Product demo</Text>
                <Text as="p" variant="bodySm" tone="subdued">
                  Variant demo
                </Text>
              </BlockStack>
            </InlineStack>

            <Text as="p">$19.99</Text>
          </InlineStack>

          <BlockStack gap="200">
            <InlineStack align="space-between" blockAlign="center">
              <Text as="p">Subtotal</Text>
              <Text as="p">$19.99</Text>
            </InlineStack>

            <InlineStack align="space-between" blockAlign="center">
              <Text as="p">Shipping</Text>
              <Text as="p">FREE</Text>
            </InlineStack>
            <InlineStack align="space-between" blockAlign="center">
              <InlineStack>
                <Text as="p">Estimated taxes </Text>
                <Icon tone="subdued" source={QuestionCircleIcon} />
              </InlineStack>
              <Text as="p">$5.99</Text>
            </InlineStack>
          </BlockStack>

          <InlineStack align="space-between" blockAlign="center">
            <Text as="p" variant="headingLg">
              Total
            </Text>
            <InlineStack gap="100">
              <Text as="p" variant="bodySm">
                USD
              </Text>
              <Text as="p" variant="headingMd">
                $25.98
              </Text>
            </InlineStack>
          </InlineStack>
        </div>
      </div>

      <Divider />
      <Box padding="400"></Box>
    </PreviewCheckoutStyled>
  );
};

export default PreviewCheckout;

export const DateOrderVerify = () => {
  const setupVerify = useSelector(setupVerifySelector);
  const dataOrders = setupVerify.dateOrder.split(',');

  const renderOrder = (label: string, value: string) => (
    <div
      key={value}
      className="checkout-input"
      style={{ padding: '4px 12px', background: '#fff', border: '1px solid #ccc', borderRadius: '6px' }}
    >
      <InlineStack wrap={false} align="space-between" blockAlign="center">
        <BlockStack>
          <Text as="span" variant="bodySm" tone="subdued">
            {label}
          </Text>
          <Text as="span">{value}</Text>
        </BlockStack>
        <Icon source={CaretDownIcon} />
      </InlineStack>
    </div>
  );

  return (
    <InlineGrid columns={3} gap="200">
      {dataOrders.map((order) => {
        switch (order) {
          case 'YY':
            return renderOrder('Year', 'YY');
          case 'MM':
            return renderOrder('Month', 'MM');
          case 'DD':
            return renderOrder('Day', 'DD');
          default:
            return null;
        }
      })}
    </InlineGrid>
  );
};
