import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { useSelector } from 'react-redux';

const UserPlans = () => {
  const isSkip = useSelector(isSkipApiSelector);
  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery('', { skip: isSkip });

  const shopifyPlanPlus = fetchDataSettings?.data?.data?.shopifyPlan === 'shopify_plus';
  // const shopifyPlanPlus = true;

  return {
    shopifyPlanPlus,
  };
};

export default UserPlans;
