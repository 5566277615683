import { images } from '@/asset';
import BannerCheckoutUI from '@/components/BannerCheckoutUI';
import { capitalizeFirstLetter } from '@/helpers/string';
import userPlans from '@/hooks/userPlans';
import {
  BannerStyleType,
  handleChangeSetupVerify,
  handleSetupErrorVerify,
  setupErrorVerifySelector,
  setupVerifySelector,
} from '@/redux/slice/checkoutVerification.slice';
import { BlockStack, Card, Divider, InlineGrid, Text, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

export const bannerStyles = [
  {
    value: BannerStyleType.Info,
    icon: images.iconInfo,
    colorPrimary: 'rgba(222, 222, 222, 1)',
    colorSecondary: 'rgba(245, 245, 245, 1)',
  },
  {
    value: BannerStyleType.Warning,
    icon: images.iconWarning,
    colorPrimary: 'rgba(255, 222, 178, 1)',
    colorSecondary: 'rgba(255, 245, 235, 1)',
  },
  {
    value: BannerStyleType.Success,
    icon: images.iconSuccess,
    colorPrimary: 'rgba(177, 242, 180, 1)',
    colorSecondary: 'rgba(234, 251, 234, 1)',
  },
  {
    value: BannerStyleType.Critical,
    icon: images.iconCritical,
    colorPrimary: 'rgba(253, 216, 216, 1)',
    colorSecondary: 'rgba(254, 246, 246, 1)',
  },
];

const BannerVerify = () => {
  const dispatch = useDispatch();
  const { shopifyPlanPlus } = userPlans();
  const setupVerify = useSelector(setupVerifySelector);
  const setupVerifyError = useSelector(setupErrorVerifySelector);

  const handleChangeSetup = (key: keyof typeof setupVerify) => (value: string | boolean) => {
    dispatch(
      handleChangeSetupVerify({
        [key]: value,
      }),
    );
  };

  return (
    <BlockStack gap="200">
      {shopifyPlanPlus && (
        <Card>
          <div className="mb-8">
            <BlockStack gap="200">
              <Text as="h4" variant="headingMd">
                Banner style
              </Text>
              <Divider />
              <>
                <Text as="h6">Style</Text>
                <InlineGrid columns={2} gap="200">
                  {bannerStyles.map((banner) => (
                    <BlockStack inlineAlign="center" key={banner.value} gap="100">
                      <div
                        onClick={() => handleChangeSetup('bannerStyle')(banner.value)}
                        style={{
                          width: '100%',
                          border: `1px solid ${banner.value === setupVerify.bannerStyle ? 'blue' : 'transparent'}`,
                          padding: '4px',
                          borderRadius: '6px',
                          cursor: 'pointer',
                        }}
                      >
                        <BannerCheckoutUI banner={banner} />
                      </div>
                      <Text as="p">{capitalizeFirstLetter(banner.value)}</Text>
                    </BlockStack>
                  ))}
                </InlineGrid>
              </>
            </BlockStack>
          </div>
        </Card>
      )}

      {
        <Card>
          <div className="mb-8">
            <BlockStack gap="200">
              <Text as="h4" variant="headingMd">
                Text customization
              </Text>
              <Divider />

              <BlockStack gap="500">
                <TextField
                  requiredIndicator
                  label="Heading"
                  value={setupVerify.heading}
                  autoComplete=""
                  onChange={(value) => {
                    if (setupVerifyError.heading) dispatch(handleSetupErrorVerify({ heading: '' }));
                    handleChangeSetup('heading')(value);
                  }}
                  onBlur={() => {
                    if (setupVerify.heading.trim().length === 0)
                      dispatch(
                        handleSetupErrorVerify({
                          heading: 'Value is required',
                        }),
                      );
                  }}
                  error={setupVerifyError.heading}
                  showCharacterCount
                  maxLength={255}
                />

                {shopifyPlanPlus && (
                  <TextField
                    requiredIndicator
                    label="Description"
                    value={setupVerify.subHeading}
                    autoComplete=""
                    onChange={(value) => {
                      if (setupVerifyError.subHeading) dispatch(handleSetupErrorVerify({ subHeading: '' }));
                      handleChangeSetup('subHeading')(value);
                    }}
                    onBlur={() => {
                      if (setupVerify.subHeading.trim().length === 0)
                        dispatch(
                          handleSetupErrorVerify({
                            subHeading: 'Value is required',
                          }),
                        );
                    }}
                    error={setupVerifyError.subHeading}
                    showCharacterCount
                    maxLength={255}
                  />
                )}
              </BlockStack>
            </BlockStack>
          </div>
        </Card>
      }
    </BlockStack>
  );
};

export default BannerVerify;
