export const colors = {
  primary: 'rgba(48, 48, 48, 1)',
  gray_background: '#F1F1F1',
  warning: 'rgba(248, 217, 144, 1)',
  critical: 'rgba(245, 107, 82, 1)',
  danger: 'rgba(197, 40, 12, 1)',
  bgHover: '#f1f1f180',
};

export const colorsCheckoutUI = {
  accent: '#1773b0',
  critical: '#dd1d1d',
  decorative: '#1773b0',
  info: '#000000',
  subdued: '#707070',
  success: '#4d7a50',
  warning: '#8f6900',
};
