import { ContextualBar } from '@/components/ContextualBar';
import useHandleContextual from '@/components/ContextualBar/HideContextual';
import Layout from '@/components/layout';
import { config } from '@/config';
import { CheckoutVerifyType, Embedded } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import {
  CheckoutApplyType,
  CheckoutValidateMethodType,
  handlePushDataCheckoutVerify,
  setupVerifyBackupSelector,
  setupVerifySelector,
  verifyTypeSelector,
} from '@/redux/slice/checkoutVerification.slice';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';
import { BlockStack, InlineGrid, Tabs, useBreakpoints } from '@shopify/polaris';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BannerVerify from './components/BannerVerify';
import ConditionVerify from './components/ConditionVerify';
import PreviewCheckout from './components/PreviewCheckout';
import { CheckoutVerificationSetupStyled } from './styled';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { handleToast } from '@/redux/slice/Toast/toast.slice';
import CriticalBanner from '@/components/CriticalBanner';
import RegularText from '@/components/RegularText';
import userPlans from '@/hooks/userPlans';

const CheckoutVerificationSetup = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { mdUp } = useBreakpoints();
  const { shopifyPlanPlus } = userPlans();

  const contextual = useHandleContextual();

  const verifyType = useSelector(verifyTypeSelector);
  const setupVerify = useSelector(setupVerifySelector);
  const setupVerifyBackup = useSelector(setupVerifyBackupSelector);
  const isShowContextualBar = useSelector(isShowSelector);

  const [error, setError] = useState({
    status: false,
    message: '',
  });
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [updateSetupCheckoutVerify, { isLoading }] = apiCaller.useUpdateSetupCheckoutVerifyMutation();

  const handleTabChange = useCallback((tabIndex: number) => setSelectedTabIndex(tabIndex), []);

  const handleDiscard = useCallback(() => {
    dispatch(handlePushDataCheckoutVerify(setupVerifyBackup));
  }, [dispatch, setupVerifyBackup]);

  const handleBackAction = () => {
    if (isShowContextualBar && config.embedded === Embedded.LIVE) {
      contextual.hide();
    } else {
      navigate(state?.prePath || PATH.CHECKOUT_VERIFICATION);
    }
  };

  useEffect(() => {
    if (verifyType === CheckoutVerifyType.DEFAULT) {
      navigate(PATH.CHECKOUT_VERIFICATION);
    }
  }, [handleDiscard, navigate, verifyType]);

  const areEqualSetupVerify = useMemo(() => isEqual(setupVerify, setupVerifyBackup), [setupVerify, setupVerifyBackup]);

  const disableButtonSave: boolean = useMemo(() => {
    const {
      applyTypeBanner,
      applyTypeInput,
      showInPageBanner,
      showInPageInput,
      heading,
      subHeading,
      method,
      headingInput,
      checkboxText,
    } = setupVerify;

    const isBannerInvalid =
      verifyType === CheckoutVerifyType.InfoBanner &&
      ((applyTypeBanner !== CheckoutApplyType.All && showInPageBanner?.length === 0) ||
        !heading ||
        (shopifyPlanPlus && !subHeading));

    const isInputInvalid =
      verifyType === CheckoutVerifyType.CheckboxOrDate &&
      ((applyTypeInput !== CheckoutApplyType.All && showInPageInput?.length === 0) ||
        (!headingInput && method === CheckoutValidateMethodType.Input) ||
        (!checkboxText && method === CheckoutValidateMethodType.CheckoutBox));

    return areEqualSetupVerify || isBannerInvalid || isInputInvalid;
  }, [areEqualSetupVerify, setupVerify, shopifyPlanPlus, verifyType]);

  const handleSaveSetupCheckout = async () => {
    try {
      const res = await updateSetupCheckoutVerify({
        ...setupVerify,
        age: +setupVerify.age,
      });

      if ('data' in res) {
        setError({
          message: '',
          status: false,
        });
        navigate(PATH.CHECKOUT_VERIFICATION);
        dispatch(handleToast(handleToastMutation(res)));
      } else {
        const isError = checkShowErrorInline(res);
        if (isError.statusCode) {
          setError({ status: true, message: isError.message });
        }
      }
    } catch (error) {
      console.log('🚀 - error:', error);
    }
  };

  const layoutSettings =
    config.embedded !== Embedded.LIVE
      ? {
          primaryAction: {
            content: 'Save',
            disabled: disableButtonSave,
            loading: isLoading,
            onAction: handleSaveSetupCheckout,
          },
          secondaryActions: [
            {
              content: 'Discard',
              onAction: handleDiscard,
              disabled: areEqualSetupVerify,
            },
          ],
        }
      : null;

  const tabs = useMemo(() => {
    return [
      {
        id: 'condition',
        content: 'Condition',
        fields: <ConditionVerify />,
      },
      {
        id: 'banner',
        content: 'Banner',
        fields: <BannerVerify />,
      },
    ];
  }, []);

  return (
    <Layout
      backAction={{ content: '', onAction: handleBackAction }}
      title="Configuration"
      // subtitle="Customize the popup to match your brand."
      {...layoutSettings}
      // isFullWidth
    >
      <CheckoutVerificationSetupStyled>
        {config.embedded === Embedded.LIVE && (
          <>
            <ContextualBar
              delay={state?.prePath}
              value={setupVerify}
              loadingSave={isLoading}
              disabledDiscard={areEqualSetupVerify}
              disabledSave={disableButtonSave}
              onDiscard={handleDiscard}
              onSave={handleSaveSetupCheckout}
            />
          </>
        )}

        <InlineGrid gap={{ sm: '200', md: '400' }} columns={mdUp ? ['oneThird', 'twoThirds'] : 1}>
          <BlockStack gap="100">
            {verifyType === CheckoutVerifyType.InfoBanner && (
              <Tabs tabs={tabs} fitted selected={selectedTabIndex} onSelect={handleTabChange} />
            )}
            <> {tabs[selectedTabIndex].fields}</>
          </BlockStack>
          <BlockStack gap="100">
            <CriticalBanner isVisible={error.status}>
              <RegularText>{error.message}</RegularText>
            </CriticalBanner>
            <PreviewCheckout />
          </BlockStack>
        </InlineGrid>
      </CheckoutVerificationSetupStyled>
    </Layout>
  );
};

export default CheckoutVerificationSetup;
