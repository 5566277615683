import BannerCheckoutUI from '@/components/BannerCheckoutUI';
import Layout from '@/components/layout';
import { CheckoutVerifyType } from '@/constants/enum';
import { PATH } from '@/constants/path';
import UserPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import {
  BannerStyleType,
  clearAllStateCheckoutVerify,
  handleChangeVerifyType,
  handlePushDataCheckoutVerify,
  setupVerifySelector,
} from '@/redux/slice/checkoutVerification.slice';
import { Badge, BlockStack, Button, Checkbox, InlineGrid, InlineStack, Text } from '@shopify/polaris';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bannerStyles } from '../CheckoutVerificationSetup/components/BannerVerify';
import { DateOrderVerify } from '../CheckoutVerificationSetup/components/PreviewCheckout';
import { CheckoutVerificationStyled } from './styled';

const CheckoutVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopifyPlanPlus } = UserPlans();

  const setupVerify = useSelector(setupVerifySelector);
  const { bannerActive, validateInputActive } = setupVerify;

  const isSkip = useSelector(isSkipApiSelector);

  const { data, isLoading } = apiCaller.useGetSetupCheckoutVerifyQuery(undefined, {
    skip: isSkip,
  });

  const listBanner = bannerStyles
    .filter((banner) => banner.value !== BannerStyleType.Info)
    .map((banner) => ({
      ...banner,
      heading:
        banner.value === BannerStyleType.Warning
          ? 'Verify your age'
          : banner.value === BannerStyleType.Success
          ? 'You’re over 18+'
          : 'You must at least 18+',
    }));

  useEffect(() => {
    if (data?.setting) {
      dispatch(handlePushDataCheckoutVerify(data.setting));
    } else {
      dispatch(clearAllStateCheckoutVerify());
    }
  }, [data, dispatch]);

  return (
    <Layout title="Checkout verification">
      <CheckoutVerificationStyled>
        <InlineGrid gap="400" columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}>
          <div className="card-item">
            <div className="card-item-top">
              <BlockStack gap="400">
                {listBanner.map((banner) => (
                  <BannerCheckoutUI key={banner.value} banner={banner} heading={banner.heading} subHeading=" " />
                ))}
              </BlockStack>
            </div>
            <div className="card-item-bottom">
              <Text as="h4" variant="headingMd">
                Info banner
              </Text>
              <Text as="h5" tone="subdued">
                Create a checkout banner to remind customers of age-restricted products.
              </Text>
              <div className="wrap-button">
                <Button
                  loading={isLoading}
                  onClick={() => {
                    dispatch(handleChangeVerifyType(CheckoutVerifyType.InfoBanner));
                    if (!data?.setting || (data?.setting && !data?.setting?.firstActive?.includes('banner'))) {
                      dispatch(
                        handlePushDataCheckoutVerify({
                          ...setupVerify,
                          bannerActive: true,
                        }),
                      );
                    }
                    navigate(PATH.CHECKOUT_VERIFICATION_SETUP);
                  }}
                >
                  Customize
                </Button>
                {!isLoading && (
                  <Badge tone={data?.setting ? (bannerActive ? 'success' : 'new') : 'new'}>
                    {data?.setting ? (bannerActive ? 'Enabled' : 'Disabled') : 'Disabled'}
                  </Badge>
                )}
              </div>
            </div>
          </div>
          <div className="card-item">
            <div className="card-item-top">
              <BlockStack gap="600">
                <InlineStack align="space-around" blockAlign="center">
                  <Badge tone="critical">18+</Badge>
                  <div className="wrap-checkbox">
                    <Checkbox label="I’m over 18 years old." />
                  </div>
                </InlineStack>
                <BlockStack gap="200">
                  <Text as="h4" variant="headingMd" tone="subdued">
                    Enter your date of birth
                  </Text>
                  <DateOrderVerify />
                  <Text as="h4" tone="critical">
                    You must over 18 years old
                  </Text>
                </BlockStack>
              </BlockStack>
            </div>
            <div className="card-item-bottom">
              <InlineStack gap="200" blockAlign="center">
                <Text as="h4" variant="headingMd">
                  Checkout verification
                </Text>
                <Badge tone="info">Shopify Plus</Badge>
              </InlineStack>
              <Text as="h5" tone="subdued">
                Verify age at checkout by checking the box or date of birth input.
              </Text>
              <div className="wrap-button">
                <Button
                  loading={isLoading}
                  disabled={!shopifyPlanPlus}
                  onClick={() => {
                    dispatch(handleChangeVerifyType(CheckoutVerifyType.CheckboxOrDate));
                    if (!data?.setting || (data?.setting && !data?.setting?.firstActive?.includes('validate'))) {
                      dispatch(
                        handlePushDataCheckoutVerify({
                          ...setupVerify,
                          validateInputActive: true,
                        }),
                      );
                    }
                    navigate(PATH.CHECKOUT_VERIFICATION_SETUP);
                  }}
                >
                  Customize
                </Button>
                {!isLoading && (
                  <Badge tone={validateInputActive && shopifyPlanPlus ? 'success' : 'new'}>
                    {validateInputActive && shopifyPlanPlus ? 'Enabled' : 'Disabled'}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        </InlineGrid>
      </CheckoutVerificationStyled>
    </Layout>
  );
};

export default CheckoutVerification;
