import { images } from '@/asset';
import BoldText from '@/components/BoldText';
import { ContextualBar } from '@/components/ContextualBar';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { Embedded, Month } from '@/constants/enum';
import { handleToastMutation } from '@/helpers';
import TextFieldTranslation from '@/pages/Translation/components/TextFieldTranslation';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { previewSelector } from '@/redux/slice/preview.slice';
import toastSlice from '@/redux/slice/Toast/toast.slice';
import {
  handleChangeTranslation,
  handleCustomizeTranslation,
  handleSelectedLanguage,
  initialState,
  selectedLanguageSelector,
  translationBackupSelector,
  translationSelector,
} from '@/redux/slice/translation.slice';
import {
  Badge,
  BlockStack,
  Button,
  Card,
  Collapsible,
  Divider,
  EmptyState,
  Icon,
  InlineGrid,
  Select,
  Text,
  useBreakpoints,
} from '@shopify/polaris';
import { ArrowRightIcon, LanguageTranslateIcon, PlusIcon } from '@shopify/polaris-icons';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TranslationStyled } from './styled';

function Translation() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { smUp } = useBreakpoints();
  const isSkipApi = useSelector(isSkipApiSelector);
  const previewTranslationDetails = useSelector(previewSelector);
  const currentTranslationDetails = useSelector(translationSelector);
  const selectedPrimaryLanguage = useSelector(selectedLanguageSelector);
  const translationBackupDetails = useSelector(translationBackupSelector);
  const [fetchLanguages, languageStatus] = apiCaller.useLazyGetLanguageQuery();
  const translationByLanguage = apiCaller.useGetTranslationQuery(
    {
      shop: config.shop,
      locale: selectedPrimaryLanguage,
    },
    { skip: isSkipApi },
  );

  const translateMonth = useMemo(() => {
    return [
      {
        id: Month.January.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.january || 'January',
        currentLabel: currentTranslationDetails?.monthsLabel?.january,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.january !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.january !== undefined,
      },
      {
        id: Month.February.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.february || 'February',
        currentLabel: currentTranslationDetails?.monthsLabel?.february,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.february !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.february !== undefined,
      },
      {
        id: Month.March.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.march || 'March',
        currentLabel: currentTranslationDetails?.monthsLabel?.march,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.march !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.march !== undefined,
      },
      {
        id: Month.April.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.april || 'April',
        currentLabel: currentTranslationDetails?.monthsLabel?.april,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.april !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.april !== undefined,
      },
      {
        id: Month.May.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.may || 'May',
        currentLabel: currentTranslationDetails?.monthsLabel?.may,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.may !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.may !== undefined,
      },
      {
        id: Month.June.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.june || 'June',
        currentLabel: currentTranslationDetails?.monthsLabel?.june,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.june !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.june !== undefined,
      },
      {
        id: Month.July.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.july || 'July',
        currentLabel: currentTranslationDetails?.monthsLabel?.july,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.july !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.july !== undefined,
      },
      {
        id: Month.August.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.august || 'August',
        currentLabel: currentTranslationDetails?.monthsLabel?.august,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.august !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.august !== undefined,
      },
      {
        id: Month.September.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.september || 'September',
        currentLabel: currentTranslationDetails?.monthsLabel?.september,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.september !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.september !== undefined,
      },
      {
        id: Month.October.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.october || 'October',
        currentLabel: currentTranslationDetails?.monthsLabel?.october,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.october !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.october !== undefined,
      },
      {
        id: Month.November.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.november || 'November',
        currentLabel: currentTranslationDetails?.monthsLabel?.november,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.november !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.november !== undefined,
      },
      {
        id: Month.December.toLocaleLowerCase(),
        previewLabel: previewTranslationDetails?.monthsLabel?.december || 'December',
        currentLabel: currentTranslationDetails?.monthsLabel?.december,
        status:
          translationByLanguage.data?.translation?.monthsLabel?.december !== '' &&
          translationByLanguage.data?.translation?.monthsLabel?.december !== undefined,
      },
    ];
  }, [previewTranslationDetails, currentTranslationDetails, translationByLanguage]);
  const labelMonths = translateMonth.filter((month) => month.status === true);
  const nonLabelMonths = translateMonth.filter((month) => month.status === false);
  const [activeLabelMonths, setActiveLabelMonths] = useState<string[]>([]);

  const handleAddMonth = () => {
    if (nonLabelMonths.length > 0 && activeLabelMonths.length < nonLabelMonths.length) {
      setActiveLabelMonths((prev) => [...prev, nonLabelMonths[activeLabelMonths.length].id]);
    }
  };
  const handleAddFromFebruary = () => {
    setIsOpenMonthLabel(true);
    setActiveLabelMonths(['january']);
    if (activeLabelMonths.length < nonLabelMonths.length) {
      const nextMonthIndex = activeLabelMonths.length + 1;
      setActiveLabelMonths((prev) => [...prev, nonLabelMonths[nextMonthIndex].id]);
    }
  };

  const generalDetailQuerySettings = apiCaller.useGetGeneralDetailQuery('', { skip: isSkipApi });
  const [updateTranslation, { isLoading }] = apiCaller.useUpdateTranslationMutation();
  const [isOpenMonthLabel, setIsOpenMonthLabel] = useState(false);
  const toggleCollapsible = () => {
    setIsOpenMonthLabel(!isOpenMonthLabel);
  };

  const handleTranslationChange = (key: string) => (value: string) => {
    dispatch(handleChangeTranslation({ ...currentTranslationDetails, [key]: value }));
  };
  const handleMonthLabelChange = (month: string) => (value: string) => {
    dispatch(
      handleChangeTranslation({
        ...currentTranslationDetails,
        monthsLabel: {
          ...currentTranslationDetails.monthsLabel,
          [month]: value,
        },
      }),
    );
  };

  const handleSaveTranslation = () => {
    updateTranslation({
      translations: {
        headingText:
          currentTranslationDetails.headingText?.trim() !== '' ? currentTranslationDetails.headingText?.trim() : undefined,
        subHeadingText:
          currentTranslationDetails.subHeadingText?.trim() !== '' ? currentTranslationDetails.subHeadingText?.trim() : undefined,
        submitButtonLabel:
          currentTranslationDetails.submitButtonLabel?.trim() !== ''
            ? currentTranslationDetails.submitButtonLabel?.trim()
            : undefined,
        cancelButtonLabel:
          currentTranslationDetails.cancelButtonLabel?.trim() !== ''
            ? currentTranslationDetails.cancelButtonLabel?.trim()
            : undefined,
        monthsLabel: {
          january:
            currentTranslationDetails.monthsLabel.january?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.january?.trim()
              : undefined,
          february:
            currentTranslationDetails.monthsLabel.february?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.february?.trim()
              : undefined,
          march:
            currentTranslationDetails.monthsLabel.march?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.march?.trim()
              : undefined,
          april:
            currentTranslationDetails.monthsLabel.april?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.april?.trim()
              : undefined,
          may:
            currentTranslationDetails.monthsLabel.may?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.may?.trim()
              : undefined,
          june:
            currentTranslationDetails.monthsLabel.june?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.june?.trim()
              : undefined,
          july:
            currentTranslationDetails.monthsLabel.july?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.july?.trim()
              : undefined,
          august:
            currentTranslationDetails.monthsLabel.august?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.august?.trim()
              : undefined,
          september:
            currentTranslationDetails.monthsLabel.september?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.september?.trim()
              : undefined,
          october:
            currentTranslationDetails.monthsLabel.october?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.october?.trim()
              : undefined,
          november:
            currentTranslationDetails.monthsLabel.november?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.november?.trim()
              : undefined,
          december:
            currentTranslationDetails.monthsLabel.december?.trim() !== ''
              ? currentTranslationDetails.monthsLabel.december?.trim()
              : undefined,
        },
        errorMessage:
          currentTranslationDetails.errorMessage?.trim() !== '' ? currentTranslationDetails.errorMessage?.trim() : undefined,
      },
      locale: selectedPrimaryLanguage,
    }).then((res) => {
      dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      setActiveLabelMonths([]);
      setIsOpenMonthLabel(false);
    });
  };

  const defaultLanguage = languageStatus && languageStatus?.data?.data?.find((item) => item.primary);
  const availableLanguages =
    languageStatus &&
    languageStatus?.data?.data
      ?.filter((item) => !item.primary)
      ?.map((item) => ({
        value: item.locale,
        label: item.name,
      }));

  const handleChangeLanguage = useCallback(
    (value: string) => {
      dispatch(handleSelectedLanguage(value));
      if (selectedPrimaryLanguage !== value) {
        setIsOpenMonthLabel(false);
        setActiveLabelMonths([]);
      }
    },
    [dispatch, selectedPrimaryLanguage],
  );

  const handleRefreshData = () => {
    fetchLanguages({ shop: config.shop });
  };

  const isTranslationsUnchanged = useMemo(
    () => isEqual(currentTranslationDetails, translationBackupDetails),
    [currentTranslationDetails, translationBackupDetails],
  );

  const handleDiscardChanges = () => {
    dispatch(handleChangeTranslation(translationBackupDetails));
  };

  const isSaveDisabled = useMemo(() => isTranslationsUnchanged, [isTranslationsUnchanged]);

  const translationLayoutSettings =
    config.embedded !== Embedded.LIVE
      ? {
          primaryAction: {
            content: 'Save',
            onAction: handleSaveTranslation,
            disabled: isSaveDisabled,
            loading: isLoading,
          },
          secondaryActions: (
            <div style={{ display: 'flex', gap: '8px' }}>
              <Button disabled={isTranslationsUnchanged} onClick={handleDiscardChanges}>
                Discard
              </Button>
            </div>
          ),
        }
      : null;

  useEffect(() => {
    if (translationByLanguage?.data?.translation) {
      const updatedTranslationDetail = {
        headingText: translationByLanguage.data?.translation?.headingText || '',
        subHeadingText: translationByLanguage.data?.translation?.subHeadingText || '',
        submitButtonLabel: translationByLanguage.data?.translation?.submitButtonLabel || '',
        cancelButtonLabel: translationByLanguage.data?.translation?.cancelButtonLabel || '',
        monthsLabel: translationByLanguage.data?.translation?.monthsLabel || {
          january: '',
          february: '',
          march: '',
          april: '',
          may: '',
          june: '',
          july: '',
          august: '',
          september: '',
          october: '',
          november: '',
          december: '',
        },
        errorMessage: translationByLanguage.data?.translation?.errorMessage || '',
      };
      dispatch(handleCustomizeTranslation(updatedTranslationDetail));
    } else {
      dispatch(handleCustomizeTranslation(initialState.translation));
    }
    // eslint-disable-next-line
  }, [translationByLanguage, dispatch]);

  useEffect(() => {
    fetchLanguages({ shop: config.shop });
  }, [fetchLanguages]);

  useEffect(() => {
    if (availableLanguages && availableLanguages.length > 0) {
      const firstLanguage = availableLanguages[0].value;
      if (!selectedPrimaryLanguage) {
        dispatch(handleSelectedLanguage(firstLanguage));
      }
    }
  }, [availableLanguages, selectedPrimaryLanguage, dispatch]);

  return (
    <Layout title="Translation" {...translationLayoutSettings}>
      <TranslationStyled>
        {config.embedded === Embedded.LIVE && (
          <ContextualBar
            delay={state?.prePath}
            value={previewTranslationDetails}
            loadingSave={isLoading}
            disabledDiscard={isTranslationsUnchanged}
            disabledSave={isSaveDisabled}
            onDiscard={handleDiscardChanges}
            onSave={handleSaveTranslation}
          />
        )}
        <div className="space-between mt-8">
          <div className="space-between align-center">
            <BoldText>
              {defaultLanguage?.name} <Badge>Default</Badge>
            </BoldText>
            <div className="mr-4 ml-4">
              <Icon source={ArrowRightIcon}></Icon>
            </div>
            <div className="selected-language">
              <Select
                label={<Icon source={LanguageTranslateIcon} />}
                labelInline
                options={availableLanguages}
                onChange={handleChangeLanguage}
                value={selectedPrimaryLanguage}
                placeholder={availableLanguages && availableLanguages.length > 0 ? undefined : 'Select language'}
              />
            </div>
          </div>
          <Button onClick={handleRefreshData} loading={languageStatus.isFetching}>
            Refresh data
          </Button>
        </div>
        <div className="mt-16">
          {!!availableLanguages?.length ? (
            <BlockStack gap={{ xs: '800', sm: '400' }}>
              <InlineGrid columns={{ xs: '1fr', md: '1fr 4fr' }} gap="400">
                <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Text
                  </Text>
                  <Text as="p" variant="bodyMd" tone="subdued">
                    Customize the input field text in the Translation form.
                  </Text>
                </BlockStack>
                <Card padding="500">
                  <BlockStack gap="400">
                    <TextFieldTranslation
                      label="Heading text"
                      defaultValue={previewTranslationDetails.headingText}
                      onChange={handleTranslationChange('headingText')}
                      value={currentTranslationDetails.headingText || ''}
                    />
                    <TextFieldTranslation
                      label="Sub-heading text"
                      defaultValue={previewTranslationDetails.subHeadingText}
                      onChange={handleTranslationChange('subHeadingText')}
                      value={currentTranslationDetails.subHeadingText || ''}
                    />
                  </BlockStack>
                </Card>
              </InlineGrid>
              {smUp && <Divider />}
              <InlineGrid columns={{ xs: '1fr', md: '1fr 4fr' }} gap="400">
                <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Button
                  </Text>
                  <Text as="p" variant="bodyMd" tone="subdued">
                    Customize labels for input fields and buttons
                  </Text>
                </BlockStack>
                <Card padding="500">
                  <BlockStack gap="400">
                    <TextFieldTranslation
                      label="Submit button label"
                      defaultValue={previewTranslationDetails.submitButtonLabel}
                      onChange={handleTranslationChange('submitButtonLabel')}
                      value={currentTranslationDetails.submitButtonLabel || ''}
                    />
                    <TextFieldTranslation
                      label="Cancel button label"
                      defaultValue={previewTranslationDetails.cancelButtonLabel}
                      onChange={handleTranslationChange('cancelButtonLabel')}
                      value={currentTranslationDetails.cancelButtonLabel || ''}
                    />
                    <TextFieldTranslation
                      label="Validation date error message"
                      defaultValue={previewTranslationDetails.errorMessage}
                      onChange={handleTranslationChange('errorMessage')}
                      value={currentTranslationDetails.errorMessage || ''}
                    />
                  </BlockStack>
                </Card>
              </InlineGrid>
              {smUp && <Divider />}
              <InlineGrid columns={{ xs: '1fr', md: '1fr 4fr' }} gap="400">
                <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Label
                  </Text>
                  <Text as="p" variant="bodyMd" tone="subdued">
                    Customize the labels used for months to match your preferences or language requirements.
                  </Text>
                </BlockStack>
                <Card padding="500">
                  <BlockStack gap="400">
                    {labelMonths.map((month, index) => {
                      return (
                        <Collapsible
                          key={month.id}
                          open={isOpenMonthLabel || index === 0}
                          id="basic-collapsible"
                          transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                          expandOnPrint
                        >
                          <TextFieldTranslation
                            label="Month label"
                            defaultValue={month.previewLabel}
                            onChange={handleMonthLabelChange(month.id)}
                            value={month.currentLabel || ''}
                          />
                        </Collapsible>
                      );
                    })}
                    {activeLabelMonths.length === 0 && labelMonths.length === 0 ? (
                      <>
                        <TextFieldTranslation
                          key={nonLabelMonths[0].id}
                          label="Month label"
                          defaultValue={nonLabelMonths[0].previewLabel}
                          onChange={handleMonthLabelChange(nonLabelMonths[0].id)}
                          value={nonLabelMonths[0].currentLabel || ''}
                        />
                        <div>
                          <Button icon={PlusIcon} onClick={handleAddFromFebruary}>
                            Add
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        {activeLabelMonths.map((monthId) => {
                          const month = nonLabelMonths.find((m) => m.id === monthId);
                          return (
                            month && (
                              <TextFieldTranslation
                                key={month.id}
                                label="Month label"
                                defaultValue={month.previewLabel}
                                onChange={handleMonthLabelChange(month.id)}
                                value={month.currentLabel || ''}
                              />
                            )
                          );
                        })}
                      </>
                    )}
                    {((activeLabelMonths.length < nonLabelMonths.length && isOpenMonthLabel) || labelMonths.length === 1) && (
                      <div>
                        <Button icon={PlusIcon} onClick={handleAddMonth}>
                          Add
                        </Button>
                      </div>
                    )}
                  </BlockStack>
                  {labelMonths.length > 1 && (
                    <div className="translate-btn-collapse">
                      <Button onClick={toggleCollapsible}>{isOpenMonthLabel ? 'See less' : 'See more'}</Button>
                    </div>
                  )}
                </Card>
              </InlineGrid>
            </BlockStack>
          ) : (
            <Card>
              <EmptyState
                heading="Manage translations for the age verification popup."
                action={{
                  content: 'Add language',
                  onAction: () =>
                    window.open(
                      `https://admin.shopify.com/store/${generalDetailQuerySettings.data?.data?.shop.replace(
                        '.myshopify.com',
                        '',
                      )}/settings/languages`,
                      '_blank',
                    ),
                }}
                image={images.emptyState}
              >
                <RegularText>Translate the age verification version into multiple languages.</RegularText>
              </EmptyState>
            </Card>
          )}
        </div>
      </TranslationStyled>
    </Layout>
  );
}

export default Translation;
