import { config } from '@/config';
import { Ga4Event, Subscription } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import sessionSlice, { gaFourSelector } from '@/redux/slice/session.slice';
import { useDispatch, useSelector } from 'react-redux';

const configSession = [Ga4Event.ClickTwitter, Ga4Event.ImpressTwitter];

export const useGa4 = () => {
  const dispatch = useDispatch();
  const gaFour = useSelector(gaFourSelector);

  const [fetchGA] = apiCaller.useFetchGAMutation();

  const handleGa4 = (
    event: Ga4Event,
    params?: {
      // plan: UserPlan;
      type: Subscription;
    },
  ) => {
    if (!gaFour[event]) {
      fetchGA({
        eventName: event,
        params,
        shop: config.shop,
      });
      const gaFourSelected = configSession.find((item) => item === event);
      if (gaFourSelected) {
        dispatch(
          sessionSlice.actions.handleGaFour({
            key: gaFourSelected as keyof typeof gaFour,
          }),
        );
      }
    }
  };
  return {
    handleGa4,
  };
};
