import { IPreview } from '@/types/components/preview';
import { IToast } from '@/types/components/toast';
import dayjs from 'dayjs';
import _ from 'lodash';

export const openCrisp = () => {
  try {
    $crisp.push(['do', 'chat:open']);
  } catch (error) {
    console.log(error);
  }
};

export const getSomeDaysAgo = (day: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - day);
  date.setHours(0, 0, 0);
  return date;
};

export const formatDate = (date: number | Date, format?: string) => {
  return dayjs(typeof date === 'number' ? date * 1000 : date).format(format ? format : 'D MMM YYYY, h:mm a');
};

export const getLastMonth = () => {
  const firstDate = new Date();
  firstDate.setDate(1);
  firstDate.setMonth(firstDate.getMonth() - 1);
  const lastDate = new Date();
  lastDate.setDate(0);
  return {
    start: firstDate,
    end: lastDate,
  };
};
export const getLastSomesMonth = (month: number) => {
  const firstDate = new Date();
  firstDate.setDate(1);
  firstDate.setMonth(firstDate.getMonth() - month);
  const lastDate = new Date();
  lastDate.setMonth(lastDate.getMonth() - month + 1);
  lastDate.setDate(0);
  return {
    start: firstDate,
    end: lastDate,
  };
};

export const dateToTimeStamp = (date: Date, isOrigin?: boolean) => {
  return isOrigin ? +date : dayjs(date).unix() * 1000;
};

export const getStoreNameFromStoreUrl = (storeUrl: string) => storeUrl.match(/^(.*?)\.myshopify\.com/)?.[1];

export const checkShowErrorInline = (
  res: any,
): {
  statusCode: boolean;
  message: string;
} => {
  try {
    if ('data' in res) {
      return {
        statusCode: res.data.statusCode !== 200,
        message: res.data.message,
      };
    } else {
      return {
        statusCode: true,
        message: res.error.data.message.toString() || 'Something happened',
      };
    }
  } catch (err) {
    return {
      statusCode: true,
      message: 'Something happened',
    };
  }
};

export const handleToastMutation = (res: any): IToast => {
  try {
    if ('data' in res) {
      if (res.data.statusCode > 302) {
        return {
          isOpen: true,
          content: res.data.message || 'Failed',
          error: true,
        };
      } else {
        return {
          isOpen: true,
          content: 'Saved',
          error: false,
        };
      }
    } else {
      return {
        isOpen: true,
        content: res.data.message || 'Something happened',
        error: false,
      };
    }
  } catch (err) {
    return {
      isOpen: true,
      content: 'Something happened',
      error: true,
    };
  }
};

export function convertNumberToStringInJson(obj: IPreview): IPreview {
  return _.cloneDeepWith(obj, (value) => {
    if (typeof value === 'number') {
      return value.toString();
    }
    if (value === null) {
      return '';
    }
  });
}

export function convertStringToNumberInJson(obj: IPreview) {
  return _.cloneDeepWith(obj, (value) => {
    if (typeof value === 'string' && !isNaN(Number(value)) && value.trim() !== '') {
      return Number(value);
    }
  });
}

export const truncateText = (text: string, maxLength: number) => {
  if (!text) return '';

  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

export function validateUrl(url: string) {
  // eslint-disable-next-line no-useless-escape
  const regex = /[(www\.)?a-zA-Z0-9@:%._\+\-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
  const result = url.toLowerCase().match(regex);
  if (result === null) return false;
  return true;
}

export function validateDomain(url: string, listValidDomain: string[]) {
  if (!/^https?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  try {
    const parsedUrl = new URL(url);
    return listValidDomain.some(domain => parsedUrl.hostname.toLocaleLowerCase() === domain.toLowerCase());
  } catch {
    return false;
  }
}