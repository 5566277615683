import { ContextualBar } from '@/components/ContextualBar';
import useHandleContextual from '@/components/ContextualBar/HideContextual';
import CriticalBanner from '@/components/CriticalBanner';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { DeviceType, Embedded } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';
import { handleChangePreview, previewBackupSelector, previewSelector } from '@/redux/slice/preview.slice';
import { handleToast } from '@/redux/slice/Toast/toast.slice';
import { IPreview } from '@/types/components/preview';
import { BlockStack, Icon, InlineGrid, Tabs, useBreakpoints } from '@shopify/polaris';
import { DesktopIcon, MobileIcon } from '@shopify/polaris-icons';
import { isEqual } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackgroundSetting from './components/BackgroundSetting';
import ButtonSettings from './components/ButtonSettings';
import Preview from './components/Preview';
import TextSetting from './components/TextSetting';
import { CustomTemplateStyled } from './styled';

const CustomTemplate = () => {
  const navigate = useNavigate();
  const { mdUp, smDown } = useBreakpoints();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const contextual = useHandleContextual();

  const previewDetail = useSelector(previewSelector);
  const previewDetailBackup = useSelector(previewBackupSelector);
  const isShowContextualBar = useSelector(isShowSelector);

  const [uploadImage] = apiCaller.useUploadImageMutation();
  const [updateTemplate, { isLoading }] = apiCaller.useUpdateTemplateMutation();

  const [error, setError] = useState({
    status: false,
    message: '',
  });
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [files, setFiles] = useState<{ logo: File[]; backgroundImage: File[] }>({
    logo: [],
    backgroundImage: [],
  });
  const [selectedDevice, setSelectedDevice] = useState(DeviceType.DESKTOP);

  const handleSelect = (type: DeviceType) => {
    setSelectedDevice(type);
  };

  const handleTabChange = useCallback((tabIndex: number) => setSelectedTabIndex(tabIndex), []);

  const arePreviewEqual = useMemo(() => {
    return isEqual(previewDetail, previewDetailBackup);
  }, [previewDetail, previewDetailBackup]);

  const tabs = useMemo(() => {
    return [
      {
        id: 'background',
        content: 'Background',
        fields: <BackgroundSetting setFiles={setFiles} />,
      },
      {
        id: 'text',
        content: 'Text',
        fields: <TextSetting />,
      },
      {
        id: 'button',
        content: 'Button',
        fields: <ButtonSettings />,
      },
    ];
  }, []);

  const handleSaveTemplate = async () => {
    try {
      let payload: IPreview = {
        ...previewDetail,
        showInPages: previewDetail.showInPages.map((item) => ({
          ...item,
          id: Number(item.id),
        })),
      };

      const uploadFile = async (file: File) => {
        const formData = new FormData();
        formData.append('image', file);
        const response = await uploadImage(formData);
        if ('data' in response) {
          return response?.data?.url;
        }
      };

      const uploadPromises = [];

      if (files.logo.length > 0) {
        uploadPromises.push(
          uploadFile(files.logo[0]).then((url) => {
            if (url) payload.logo = url;
            setFiles((prev) => ({ ...prev, logo: [] }));
          }),
        );
      }

      if (files.backgroundImage.length > 0) {
        uploadPromises.push(
          uploadFile(files.backgroundImage[0]).then((url) => {
            if (url) payload.mainBackground = url;
            setFiles((prev) => ({ ...prev, backgroundImage: [] }));
          }),
        );
      }

      await Promise.all(uploadPromises);

      const res = await updateTemplate(payload);
      if ('data' in res) {
        setError({
          message: '',
          status: false,
        });
        dispatch(handleToast(handleToastMutation(res)));
      } else {
        const isError = checkShowErrorInline(res);
        if (isError.statusCode) {
          setError({ status: true, message: isError.message });
        }
      }
    } catch (error) {
      console.log('🚀 - Upload file error:', error);
    }
  };

  const handleDiscard = () => {
    dispatch(handleChangePreview(previewDetailBackup));
  };

  const handleBackAction = () => {
    if (isShowContextualBar && config.embedded === Embedded.LIVE) {
      contextual.hide();
    } else {
      // dispatch(clearState(initialState));
      navigate(state?.prePath || PATH.POP_UP_CUSTOMIZATION);
      handleDiscard();
    }
  };

  const layoutSettings =
    config.embedded !== Embedded.LIVE
      ? {
          primaryAction: {
            content: 'Save',
            disabled: arePreviewEqual,
            loading: isLoading,
            onAction: handleSaveTemplate,
          },
          secondaryActions: [
            {
              content: 'Discard',
              onAction: handleDiscard,
              disabled: arePreviewEqual,
            },
          ],
        }
      : null;

  return (
    <Layout
      backAction={{ content: '', onAction: handleBackAction }}
      title="Configuration"
      subtitle="Customize the popup to match your brand."
      {...layoutSettings}
    >
      <CustomTemplateStyled>
        <div className="preview-heading">
          {config.embedded === Embedded.LIVE && (
            <>
              <ContextualBar
                delay={state?.prePath}
                value={previewDetail}
                loadingSave={isLoading}
                disabledDiscard={arePreviewEqual}
                disabledSave={arePreviewEqual}
                onDiscard={handleDiscard}
                onSave={handleSaveTemplate}
              />
            </>
          )}
        </div>
        {/* {selectedDevice === DeviceType.DESKTOP ? (
          <Card>
            <div className="btn-cancel">
              <Button icon={XIcon} variant="plain" onClick={() => handleSelect(DeviceType.MOBILE)} />
            </div>
            <Preview fullscreen />
          </Card>
        ) : (

        )} */}
        <InlineGrid gap={{ sm: '200', md: '400' }} columns={mdUp ? ['oneThird', 'twoThirds'] : 1}>
          <BlockStack gap="100">
            <Tabs tabs={tabs} fitted selected={selectedTabIndex} onSelect={handleTabChange}></Tabs>
            <> {tabs[selectedTabIndex].fields}</>
          </BlockStack>
          <BlockStack>
            <div className="mt-8">
              <CriticalBanner isVisible={error.status}>
                <RegularText>{error.message}</RegularText>
              </CriticalBanner>
            </div>
            <div className="card-right">
              <div className={smDown ? 'card-btn-device-mobile' : 'card-btn-device'}>
                {smDown ? null : (
                  <>
                    <div
                      onClick={() => handleSelect(DeviceType.DESKTOP)}
                      className={`card-btn-device-item ${selectedDevice === DeviceType.DESKTOP ? 'active' : ''}`}
                    >
                      <Icon source={DesktopIcon} />
                    </div>
                    <div
                      onClick={() => handleSelect(DeviceType.MOBILE)}
                      className={`card-btn-device-item ${selectedDevice === DeviceType.MOBILE ? 'active' : ''}`}
                    >
                      <Icon source={MobileIcon} />
                    </div>
                  </>
                )}
              </div>
              {selectedDevice === DeviceType.DESKTOP ? (
                <Preview></Preview>
              ) : (
                <div className="card-right-preview">
                  <Preview />
                </div>
              )}
            </div>
          </BlockStack>
        </InlineGrid>
      </CustomTemplateStyled>
    </Layout>
  );
};

export default memo(CustomTemplate);
