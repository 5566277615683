import logo from '@/asset/dashboard/logo.webp';
import emptyState from '@/asset/emptyState.png';
import twitter from '@/asset/dashboard/twitter.png';
import iconInfo from '@/asset/icon/icon-info.png';
import iconWarning from '@/asset/icon/icon-warning.png';
import iconSuccess from '@/asset/icon/icon-success.png';
import iconCritical from '@/asset/icon/icon-critical.png';
import blockify from '@/asset/dashboard/blockify.png';

export const images = { logo, iconInfo, iconWarning, iconSuccess, iconCritical, emptyState, twitter, blockify };
