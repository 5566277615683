import useGetCollections from '@/hooks/useGetCollections';
import useGetProducts from '@/hooks/useGetProducts';
import {
  CheckoutApplyType,
  handleChangeSetupVerify,
  handleSetupErrorVerify,
  setupErrorVerifySelector,
  setupVerifySelector,
  verifyTypeSelector,
} from '@/redux/slice/checkoutVerification.slice';
import { AutoSelection, Combobox, Icon, Listbox } from '@shopify/polaris';
import { SearchIcon, SelectIcon, XSmallIcon } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductOrCollectionListStyled } from './styled';
import { CheckoutVerifyType } from '@/constants/enum';

interface Props {}

const ProductOrCollectionList: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const setupVerify = useSelector(setupVerifySelector);
  const verifyType = useSelector(verifyTypeSelector);
  const setupError = useSelector(setupErrorVerifySelector);
  const { applyTypeBanner, applyTypeInput, showInPageBanner, showInPageInput } = setupVerify ?? {};

  const { collectionOptions, debouncedGetCollections, getCollectionsStatus } = useGetCollections();
  const { debouncedGetProducts, getProductsStatus, productOptions } = useGetProducts();

  const [valueSearch, setValueSearch] = useState('');

  const applyType = verifyType === CheckoutVerifyType.InfoBanner ? applyTypeBanner : applyTypeInput;
  const showInPages = verifyType === CheckoutVerifyType.InfoBanner ? showInPageBanner : showInPageInput;
  const optionOfficial = applyType === CheckoutApplyType.SpecificProduct ? productOptions : collectionOptions;

  const handleBlur = () => {
    if (showInPages?.length === 0) {
      const errorMessage = applyType === CheckoutApplyType.SpecificProduct ? 'Product is required' : 'Collection is required';

      dispatch(handleSetupErrorVerify({ showInPage: errorMessage }));
    }
  };

  const updateSelection = useCallback(
    (selected: string) => {
      const currentSelected = showInPages?.map((item) => String(item.id));
      const isSelected = currentSelected.includes(selected);

      const newSelected = {
        title: optionOfficial.find((item) => item.value === selected)?.label,
        id: +selected,
      };

      const newInputValues = isSelected
        ? showInPages?.filter((item) => String(item.id) !== selected)
        : [...showInPages, newSelected];

      const newShowInPage =
        verifyType === CheckoutVerifyType.InfoBanner
          ? {
              showInPageBanner: newInputValues,
            }
          : {
              showInPageInput: newInputValues,
            };

      dispatch(handleChangeSetupVerify(newShowInPage));

      if (setupError.showInPage) {
        dispatch(handleSetupErrorVerify({ showInPage: '' }));
      }
    },
    [showInPages, optionOfficial, verifyType, dispatch, setupError.showInPage],
  );

  const optionsMarkup =
    optionOfficial.length > 0
      ? optionOfficial.map(({ label, value }) => (
          <Listbox.Option
            key={value}
            value={value}
            selected={showInPages?.map((item) => String(item?.id))?.includes(value)}
            accessibilityLabel={label}
          >
            {label}
          </Listbox.Option>
        ))
      : null;

  const updateText = useCallback(
    (value: string) => {
      if (applyType === CheckoutApplyType.SpecificCollection) {
        debouncedGetCollections(value);
      } else {
        debouncedGetProducts(value);
      }

      setValueSearch(value);
    },
    [applyType, debouncedGetCollections, debouncedGetProducts],
  );

  const removeItem = (id: number) => {
    const newValues = showInPages?.filter((item) => item.id !== id);

    const newShowInPage =
      verifyType === CheckoutVerifyType.InfoBanner
        ? {
            showInPageBanner: newValues,
          }
        : {
            showInPageInput: newValues,
          };

    dispatch(handleChangeSetupVerify(newShowInPage));

    dispatch(
      handleSetupErrorVerify({
        showInPage: !newValues.length
          ? `${applyType === CheckoutApplyType.SpecificCollection ? 'Collection' : 'Product'} is required`
          : '',
      }),
    );
  };

  return (
    <ProductOrCollectionListStyled>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            requiredIndicator
            prefix={<Icon source={SearchIcon} />}
            onChange={updateText}
            label=""
            value={valueSearch}
            placeholder={`Search ${applyType === CheckoutApplyType.SpecificProduct ? 'product' : 'collection'}`}
            autoComplete="off"
            onBlur={handleBlur}
            disabled={getCollectionsStatus.isLoading || getProductsStatus.isLoading}
            error={setupError.showInPage}
            id="ruleValue"
            suffix={<Icon source={SelectIcon} />}
          />
        }
      >
        {optionsMarkup ? (
          <Listbox autoSelection={AutoSelection.None} onSelect={updateSelection}>
            {optionsMarkup}
          </Listbox>
        ) : null}
      </Combobox>

      <div className="selected-tag-full-width">
        <div className="width-100 d-flex flex-wrap">
          {showInPages?.map(({ title, id }) => {
            return (
              <div className="mr-8 Polaris-Tag mb-4 break-word" key={id}>
                <h1 title={title} className="Polaris-Tag__TagText">
                  {title}
                </h1>
                <button className="Polaris-Tag__Button" onClick={() => removeItem(id)}>
                  <Icon source={XSmallIcon}></Icon>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </ProductOrCollectionListStyled>
  );
};

export default ProductOrCollectionList;
