import { ITranslation } from '@/types/components/translation';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const translation: ITranslation = {
  headingText: '',
  subHeadingText: '',
  submitButtonLabel: '',
  cancelButtonLabel: '',
  monthsLabel: {
    january: '',
    february: '',
    march: '',
    april: '',
    may: '',
    june: '',
    july: '',
    august: '',
    september: '',
    october: '',
    november: '',
    december: '',
  },
  errorMessage: '',
};

export const initialState = {
  translation: translation,
  translationBackup: translation,
  selectedLanguage: ''
};

export const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    handleChangeTranslation: (state, action: PayloadAction<Partial<ITranslation>>) => {
      state.translation = {
        ...state.translation,
        ...action.payload,
      };
    },
    handleCustomizeTranslation: (state, action: PayloadAction<ITranslation>) => {
      state.translation = action.payload;
      state.translationBackup = action.payload;
    },
    handleSelectedLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
    },
    clearAllState: (state) => {
      state.translation = translation;
      state.translationBackup = translation;
    },
  },
});

export const translationSelector = createSelector(
  (state: RootState) => state.translation,
  (state) => state.translation,
);

export const translationBackupSelector = createSelector(
  (state: RootState) => state.translation,
  (state) => state.translationBackup,
);

export const selectedLanguageSelector = createSelector(
  (state: RootState) => state.translation,
  (state) => state.selectedLanguage,
);

export const { handleChangeTranslation, handleCustomizeTranslation, handleSelectedLanguage } = translationSlice.actions;
export default translationSlice;
